// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cybersecurity-live-quiz-index-js": () => import("./../../../src/pages/cybersecurity-live-quiz/index.js" /* webpackChunkName: "component---src-pages-cybersecurity-live-quiz-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-for-busy-parents-index-js": () => import("./../../../src/pages/lp/for-busy-parents/index.js" /* webpackChunkName: "component---src-pages-lp-for-busy-parents-index-js" */),
  "component---src-pages-lp-survey-for-parents-index-js": () => import("./../../../src/pages/lp/survey-for-parents/index.js" /* webpackChunkName: "component---src-pages-lp-survey-for-parents-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-survey-for-parents-index-js": () => import("./../../../src/pages/survey-for-parents/index.js" /* webpackChunkName: "component---src-pages-survey-for-parents-index-js" */),
  "component---src-pages-survey-for-parents-reward-js": () => import("./../../../src/pages/survey-for-parents/reward.js" /* webpackChunkName: "component---src-pages-survey-for-parents-reward-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

